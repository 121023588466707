import { useEffect } from 'react';
import pageStyle from "./Pages.module.css"
import styles from './AboutMe.module.css'

function AboutMe() {

    useEffect(() => {
        document.title = "RASKO - ABOUT ME"
    }, []);

    return (
        <body>
            <div className={styles.mainDiv}>

                <h1 className={pageStyle.h1}>About Me</h1>

                <div className={styles.paragraph}>

                    Started learning programming at the age of 12. Since than it's my passion.
                    I have studied Mechanical Engineering at
                    Izmir Institute of Technology.
                    After graduation, decided to change my career path to programming.
                    I have been accepted to N11 Talenthub Java Bootcamp
                    Turkish e-commerce giant which is acquired by Getir.
                    During the bootcamp, the topics below are taught.
                </div>

                <br></br>
                <ul className={styles.unorderedList}>
                    <li className="marginLeftList">OOP Concepts with Java</li>
                    <li className="marginLeftList">JPA, Hibernate</li>
                    <li className="marginLeftList">Spring Framework, IOC, DI, Bean Life Cycle</li>
                    <li className="marginLeftList">CRUD Operations, Transactions</li>
                    <li className="marginLeftList">MySQL, PostgreSQL, MongoDB</li>
                    <li className="marginLeftList">Testing</li>
                </ul>
                <div className={styles.paragraph}>

                    In September of 2022, I started to work as Java Developer at Roxor Gaming in London.
                    I worked there for 5 months. Although, I worked for short period of time, I learned many things there.
                    These are;
                </div>

                <ul className={styles.unorderedList}>
                    <li className="marginLeftList">Quarkus</li>
                    <li className="marginLeftList">Gitlab CI</li>
                    <li className="marginLeftList">Bash Scripting</li>
                    <li className="marginLeftList">Docker</li>
                    <li className="marginLeftList">Kubernetes</li>
                </ul>

                <div className={styles.paragraph}>

                     In my free times, I keep learning new things about programming.
                    Sometimes I go for a walk or gym. I really like fishing, I don't really have time for it here in London.
                </div>
            </div >
        </body>
    );
}

export default AboutMe;