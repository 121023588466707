import styles from './Skills.module.css'
import { useEffect } from 'react';
import pageStyle from "./Pages.module.css"

function Skills() {
    useEffect(() => {
        document.title = "RASKO - SKILLS"
    }, []);
    return (
        <div className={styles.mainDiv}>
            <div className="centered">
                <h1 className={pageStyle.h1}>Skills</h1>
            </div>

            <div className={styles.div}>
                <table className={styles.table}>
                    <tbody>
                        {/* TODO : remove the some of the images background */}
                        <tr>
                            <th className={styles.th} rowSpan={2}>Programming<br></br>Languages</th>
                            <td className={styles.td} ><img src="images/java.png" className={styles.Images} alt="Java" /></td>
                            <td className={styles.td}><img src="images/python.png" className={styles.Images} alt="Python" /></td>
                            <td className={styles.td}><img src="images/golang.png" className={styles.Images} alt="Golang" /></td>
                            <td className={styles.td}><img src="images/javascript.png" className={styles.Images} alt="Javascript" /></td>
                            <td className={styles.td}><img src="images/matlab.png" className={styles.Images} alt="Matlab" /></td>

                        </tr>
                        <tr>
                            <td className={styles.td}>Java</td>
                            <td className={styles.td}>Python</td>
                            <td className={styles.td}>Golang</td>
                            <td className={styles.td}>Javascript</td>
                            <td className={styles.td}>Matlab</td>
                        </tr>

                        {/*  */}
                        <tr>
                            <th className={styles.th} rowSpan={2}>Backend</th>
                            <td className={styles.td}><img src="images/springboot.png" className={styles.Images} alt="Spring Boot" /></td>
                            <td className={styles.td}><img src="images/quarkus.png" className={styles.Images} alt="Quarkus" /></td>
                            <td className={styles.td}><img src="images/gin.png" className={styles.Images} alt="Gin" /></td>
                            <td className={styles.td}><img src="images/django.png" className={styles.Images} alt="Django" /></td>

                        </tr>
                        <tr>
                            <td className={styles.td} >Spring<br></br>Boot</td>
                            <td className={styles.td}>Quarkus</td>
                            <td className={styles.td}>Gin</td>
                            <td className={styles.td}>Django</td>
                        </tr>
                        {/*  */}
                        <tr>
                            <th className={styles.th} rowSpan={2}>Databases</th>
                            <td className={styles.td}><img src="images/mongodb.png" className={styles.Images} alt="MongoDB" /></td>
                            <td className={styles.td}><img src="images/postgresql.png" className={styles.Images} alt="PostgreSQL" /></td>
                            <td className={styles.td}><img src="images/mysql.png" className={styles.Images} alt="MySQL" /></td>

                        </tr>
                        <tr>

                            <td className={styles.td}>MongoDB</td>
                            <td className={styles.td}>PostgreSQL</td>
                            <td className={styles.td}>MySQL</td>
                        </tr>

                        {/*  */}
                        <tr>
                            <th className={styles.th} rowSpan={2}>Frontend</th>
                            <td className={styles.td}><img src="images/react.png" className={styles.Images} alt="React" /></td>
                            <td className={styles.td}><img src="images/css.png" className={styles.Images} alt="CSS" /></td>
                            <td className={styles.td}><img src="images/html.png" className={styles.Images} alt="HTML" /></td>

                        </tr>
                        <tr>
                            <td className={styles.td}>React</td>
                            <td className={styles.td}>CSS</td>
                            <td className={styles.td}>HTML</td>
                        </tr>

                        {/*  */}
                        <tr>
                            <th className={styles.th} rowSpan={2}>Dev Tools</th>
                            <td className={styles.td}><img src="images/git.png" className={styles.Images} alt="Git" /></td>
                            <td className={styles.td}><img src="images/docker.png" className={styles.Images} alt="Docker" /></td>
                            <td className={styles.td}><img src="images/podman.png" className={styles.Images} alt="Podman" /></td>
                            <td className={styles.td}><img src="images/kubernetes.png" className={styles.Images} alt="Kubernetes" /></td>

                        </tr>
                        <tr>
                            <td className={styles.td} >Git</td>
                            <td className={styles.td}>Docker</td>
                            <td className={styles.td}>Podman</td>
                            <td className={styles.td}>Kubernetes</td>
                        </tr>

                        {/*  */}


                    </tbody>

                </table>
            </div>








        </div>
    );
}

export default Skills;