import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MyFooter from './components/MyFooter';
import MyNavbar from './components/MyNavbar';
import Home from './pages/Home';
import AboutMe from './pages/AboutMe'
import Skills from './pages/Skills';
import Contact from './pages/Contact';
import Projects from './pages/Projects';


function App() {
  return (
    <Router>
      <MyNavbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-me" element={<AboutMe />} />
        <Route path="/skills" element={<Skills />} />
         <Route path="/projects/" element={<Projects />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/*" element={<h1> 404</h1>} />  
      </Routes>
      <MyFooter />
    </Router>
  );
}

export default App;
