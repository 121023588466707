import styles from './Contact.module.css'
import { useEffect } from 'react';
import pageStyle from './Pages.module.css'

function Contact() {
    useEffect(() => {
        document.title = "RASKO - CONTACT"
    }, []);
    return (
        <div className={styles.mainDiv}>
            <div className="centered">
                <h1 className={pageStyle.h1}>Contact</h1>
            </div>
            <div className={styles.divCenter}>

                <div className={styles.div}>
                    <table className={styles.table}>
                        <tbody>
                            <tr>
                                <th className={styles.th}>
                                    <a href='mailto: root@rasko.io'>
                                        <img src='images/contact/email.png' className={styles.EmailImage} alt='email' />
                                    </a>

                                </th>
                                <th className={styles.th}>
                                    <a href='https://www.linkedin.com/in/cftc/' target='_blank' rel='noreferrer'>
                                        <img src='images/contact/linkedin.png' className={styles.Images} alt='linkedin' />
                                    </a>

                                </th>
                                <th className={styles.th}>
                                    <a href='https://github.com/rsmciftci' target='_blank' rel='noreferrer'>
                                        <img src='images/contact/github.png' className={styles.Images} alt='github' />
                                    </a>

                                </th>
                            </tr>
                            <tr>
                                <td className={styles.td}>
                                    Email
                                </td>
                                <td className={styles.td}>
                                    Linkedin
                                </td>
                                <td className={styles.td}>
                                    Github
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>

                <div className={styles.div}>
                    Please, get in touch.
                    <br></br>
                    It would be great to hear from you!
                </div>
            </div>
        </div>
    );
}

export default Contact;