import { useEffect } from 'react';
import styles from './Project.module.css'
import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import pageStyle from './Pages.module.css'


function Projects() {


    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    useEffect(() => {
        document.title = "RASKO - PROJECTS"
    }, []);



    return (
       
            <div className={styles.mainDiv}>
                <div>
                    <h1 className={pageStyle.h1}>Projects</h1>
                </div>
                <div className={styles.carouselDiv}>
                    <div className={styles.width}>
                        <Carousel activeIndex={index} onSelect={handleSelect} data-bs-theme="dark">
                            <Carousel.Item>
                                <a href='https://github.com/rsmciftci/the-recruiter' rel="noreferrer" target='_blank'>
                                    <img
                                        className={styles.image}
                                        src='projects/the-recruiter/profile.png' alt='the-recruiter' />
                                </a>
                                <Carousel.Caption>
                                    <h4 className={styles.h32}>The Recruiter</h4>
                                    <h4 className={styles.h32}>Django Rest & React </h4>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <a href='https://github.com/n11-TalentHub-Java-Bootcamp/n11-talenthub-bootcamp-graduation-project-rsmciftci' rel="noreferrer" target='_blank'>
                                    <img
                                        className={styles.image}
                                        src='projects/n11-graduation/credit-application.png' alt='Credit Application App' /></a>
                                <Carousel.Caption>
                                    <h4 className={styles.h32}>N11 Talenthub Graduation Project</h4>
                                    <h4 className={styles.h32}>Spring Boot & React</h4>

                                </Carousel.Caption>
                            </Carousel.Item>

                            <Carousel.Item>
                                <a href='https://github.com/rsmciftci/VocabularyBuilderWithSubtitles' rel="noreferrer" target='_blank'>


                                    <img
                                        className={styles.image}
                                        src='projects/vocabulary-builder/vocabulary-builder.png' alt='vocabulary builder' /></a>

                                <Carousel.Caption>
                                    <h4 className={styles.h32}>Vocabulary Builder</h4>
                                    <h4 className={styles.h32}>Java Swing</h4>

                                </Carousel.Caption>
                            </Carousel.Item>

                            <Carousel.Item>
                                <a href='https://r4sk0.blogspot.com/2021/03/chegg-book-scraping-with-selenium.html' rel="noreferrer" target='_blank'>



                                    <img
                                        className={styles.image}
                                        src='projects/chegg-scraper.png' alt='chegg scraper' /></a>
                                <Carousel.Caption>
                                    <h4 className={styles.h32}>Chegg Book Scraper</h4>
                                    <h4 className={styles.h32}>Python, Selenium</h4>

                                </Carousel.Caption>
                            </Carousel.Item>

                            <Carousel.Item>
                                <a href='https://drive.google.com/drive/folders/1WbyRZtwaus2b58r0apwqXFvY0N4oKSv-' rel="noreferrer" target='_blank'>

                                    <img
                                        className={styles.image}
                                        src='projects/uni-gradiation-project/design.png' alt='Blood Pressure Measurement Test Setup' /></a>
                                <Carousel.Caption>
                                    <h4 className={styles.h32}>Design of Experimental Setup for Blood Pressure Measurement</h4>

                                </Carousel.Caption>
                            </Carousel.Item>





                        </Carousel>
                    </div>
                </div>


            </div>
   
    );
}

export default Projects;