
import styles from './MyFooter.module.css'

function MyFooter() {
    return (


        <div className={styles.container}>
            <p>Website built by Rasko @ 2024 </p>
        </div>

    );
}

export default MyFooter;