import { useEffect } from "react";
import styles from "./Home.module.css"
import pageStyle from "./Pages.module.css"

function Home() {

    useEffect(() => {
        document.title = "RASKO"
    }, []);


    return (
        <div>

            <h1 className={pageStyle.h1}>Rasim CHIFTCHI</h1>

            <div className={styles.imageDiv}>
                <img className={styles.profileImage} src='profile.png' />
            </div>

            <div className={styles.slidingContainer}>
                <div className={styles.slidingDiv}>
                    <h6 className={styles.slidingText} >I'm self-taught developer.I love to code...</h6>
                </div>
            </div>



        </div>
    );
}

export default Home;