
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from 'react-router-dom';

function MyNavbar() {
    return (
        <Navbar bg="dark" data-bs-theme="dark">
            <Container>
                <Navbar.Brand href="/">
                    <img
                        alt="rasko"
                        src="rasko.png"
                        height={31}
                        width={81}


                    />
                </Navbar.Brand>
                <Nav className="me-auto">

                </Nav>
                <Nav >
                    <Nav.Link to="/about-me" as={NavLink} >About Me</Nav.Link>
                    <Nav.Link to="/skills" as={NavLink} >Skills</Nav.Link>
                    <Nav.Link to="/projects" as={NavLink} >Projects</Nav.Link>
                    <Nav.Link to="/contact" as={NavLink} >Contact</Nav.Link>
                </Nav>
            </Container>
        </Navbar>

    );
}

export default MyNavbar;